var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Drawer, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../../../components/logo';
import { NavSectionVertical } from '../../../components/nav-section';
import Scrollbar from '../../../components/scrollbar';
import { NAV } from '../../../config-global';
import useResponsive from '../../../hooks/useResponsive';
import NavAccount from './NavAccount';
import useNavConfig from './navConfig';
import NavToggleButton from './NavToggleButton';
import takiPalestine from '../../../assets/images/logo/taki-palestine.svg';
// ----------------------------------------------------------------------
var StyledRoot = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        display: 'flex',
        alignItems: 'center',
        gap: '10%',
        padding: theme.spacing(2, 2.5),
    });
});
export default function NavVertical(_a) {
    var openNav = _a.openNav, onCloseNav = _a.onCloseNav;
    var pathname = useLocation().pathname;
    var navConfig = useNavConfig();
    var isDesktop = useResponsive('up', 'lg');
    useEffect(function () {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    var renderContent = (_jsxs(Scrollbar, __assign({ sx: {
            height: 1,
            '& .simplebar-content': {
                height: 1,
                display: 'flex',
                flexDirection: 'column',
            },
        } }, { children: [_jsxs(Stack, __assign({ spacing: 5, sx: {
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                } }, { children: [_jsx(StyledRoot, { children: _jsx(Logo, { src: takiPalestine }) }), _jsx(NavAccount, {})] })), _jsx(NavSectionVertical, { data: navConfig }), _jsx(Box, { sx: { flexGrow: 1 } })] })));
    return (_jsxs(Box, __assign({ component: "nav", sx: {
            flexShrink: { lg: 0 },
            width: { lg: NAV.W_DASHBOARD },
        } }, { children: [_jsx(NavToggleButton, {}), isDesktop ? (_jsx(Drawer, __assign({ open: true, variant: "permanent", PaperProps: {
                    sx: {
                        zIndex: 0,
                        width: NAV.W_DASHBOARD,
                        bgcolor: 'transparent',
                        borderRightStyle: 'dashed',
                    },
                } }, { children: renderContent }))) : (_jsx(Drawer, __assign({ open: openNav, onClose: onCloseNav, ModalProps: {
                    keepMounted: true,
                }, PaperProps: {
                    sx: {
                        width: NAV.W_DASHBOARD,
                    },
                } }, { children: renderContent })))] })));
}
